<template>
  <div v-if="fullPractice" class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>{{ this.pageTitle }}</h1>
    <h6>{{ $t("practice.form.section") }}</h6>
    <div>
      <el-row class="staticSectionRow title">
        <el-col :span="2">#</el-col>
        <el-col v-if="isScoringTypeSatOrAct" :span="4">{{
          $t("practice.form.section")
        }}</el-col>
        <el-col :span="12">{{ $t("practice.form.name") }}</el-col>
        <el-col :span="3">{{ $t("column.questionCount") }}</el-col>
        <el-col :span="3">{{ $t("column.time") }}</el-col>
      </el-row>
      <div
        :key="practice.id"
        v-for="(practice, index) in fullPractice.compose_practice_practices"
      >
        <el-row class="staticSectionRow td">
          <el-col :span="2">{{ index + 1 }}</el-col>
          <el-col v-if="isScoringTypeSatOrAct" :span="4">
            {{ practice.scoring_type }}
          </el-col>
          <el-col :span="12">{{ practice.practice.exam.title }}</el-col>
          <el-col :span="3">{{
            practice.practice.exam.questions.length
          }}</el-col>
          <el-col :span="3">
            {{
              practice.practice.has_timing
                ? instant.formatSecondToMMSS(
                    practice.practice.timing_minutes * 60 +
                      practice.practice.timing_seconds
                  )
                : "--:--"
            }}
          </el-col>
        </el-row>
        <el-row
          v-if="index < fullPractice.compose_practice_practices.length - 1"
          class="staticSectionRow td"
        >
          <el-col :span="2"
            ><div style="visibility: hidden">Empty Block</div></el-col
          >
          <el-col v-if="isScoringTypeSatOrAct" :span="4">
            <div style="visibility: hidden">Empty Block</div>
          </el-col>
          <el-col :span="15">Break</el-col>
          <el-col :span="2">
            {{ instant.formatSecondToMMSS(practice.break_seconds) }}
          </el-col>
        </el-row>
      </div>
    </div>
    <h6>
      {{
        `${$t("practice.form.limit")} ${
          fullPractice.has_expire ? `${fullPractice.expire_end}` : "--"
        }`
      }}
    </h6>
    <el-alert type="success" :closable="false">
      <div style="font-size: 16px">
        {{ $t("practice.pleasePressStartPractice") }}
      </div>
    </el-alert>
    <el-button
      type="primary"
      style="width: 400px; margin-top: 20px"
      @click="() => startPractice($route.params.fullPracticeId)"
    >
      {{ $t("practice.start") }}
    </el-button>
  </div>
</template>

<script>
import { instant } from "@ivy-way/material";
import FullPractice from "@/views/practices/FullPractice";
import Breadcrumb from "@/components/Breadcrumb";
import fullPracticesApi from "@/apis/fullPractices";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb },
  data() {
    return {
      fullPractice: null
    };
  },
  computed: {
    pageTitle() {
      return this.fullPractice ? this.fullPractice.exam.title : "";
    },
    instant() {
      return instant;
    },
    isScoringTypeSatOrAct() {
      return (
        this.fullPractice.scoring_type === "SAT" ||
        this.fullPractice.scoring_type === "ACT"
      );
    }
  },
  async created() {
    await this.fetchFullPractice();
  },
  methods: {
    async fetchFullPractice() {
      const { compose_practice } = await fullPracticesApi.fetchFullPractice(
        this.$route.params.fullPracticeId
      );
      this.fullPractice = new FullPractice(compose_practice);
    },
    async startPractice(fullPracticeId) {
      const exam = await fullPracticesApi.checkAlreadyTakenExam(fullPracticeId);
      let userExamId = exam.user_exam_id;

      if (userExamId) {
        this.$confirm(
          "請完成未完成的練習。",
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            // cancelButtonText: this.$t("practice.startNewPractice"),
            type: "warning"
          }
        ).then(() => {
          this.$router.push({
            name: "PracticeSection",
            params: {
              fullPracticeId: this.$route.params.fullPracticeId,
              userExamId: userExamId,
              sectionOrder: 1,
              questionOrder: 1
            }
          });
        });
      }
      if (!userExamId) {
        const { user_exam } = await fullPracticesApi.takeFullPracticeExam(
          fullPracticeId
        );
        userExamId = user_exam.id;
        this.$router.push({
          name: "PracticeSection",
          params: {
            fullPracticeId: this.$route.params.fullPracticeId,
            userExamId: userExamId,
            sectionOrder: 1,
            questionOrder: 1
          }
        });
      }


    }
  }
};
</script>

<style>
.questionOrderLink {
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  text-align: center;
  line-height: 25px;
  border: #ddd 4px solid;
  margin: 0px 8px 8px 0px;
}

.activeQuestion {
  border: #42a16a 4px solid;
}

.questionOrderLink:hover {
  border: #42a16a 4px solid;
}

.title {
  font-weight: bold;
  color: #909399;
}

.td {
  color: #606266;
}

.staticSectionRow {
  padding: 8px 20px;
  border-bottom: 1px solid #ebeef5;
  font-size: 14px;
}
</style>
